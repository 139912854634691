<template>
  <div class="relative flex flex-col overflow-hidden bg-gray-900 rounded-xl">
    <div class="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-green-400 via-green-600 to-green-300"></div>
    <div class="flex flex-col items-center p-10 md:flex-row">
      <div class="w-full text-white md:w-2/3">
        <div class="flex items-end">
          <p class="flex items-start text-lg font-bold leading-none text-transparent text-7xl bg-clip-text bg-gradient-to-tr from-green-600 to-green-500">
            <span class="mt-2 mr-4 text-4xl">
              <SolanaIcon class="w-10 h-10" />
            </span>
            {{ total.toFixed(2) }}
          </p>
          <div class="flex flex-col ml-3 mb-1">
            <p class="text-base font-medium leading-tight text-gray-200 mb-1">for {{ selected.value }}x Space Snek</p>
            <p v-if="wallet" class="flex-shrink-0 text-sm text-gray-400 truncate">
              Wallet: {{ $filters.addressFmt(wallet.publicKey.toBase58()) }}
            </p>
          </div>
        </div>
      </div>

      <div class="flex items-center w-full mt-8 md:w-1/3 md:justify-end md:mt-0">
        <ConfettiExplosion v-if="confetti" :colors="['#A7F3D0', '#34D399', '#059669', '#10B981']" :particle-count="200" :force="0.3" />
        <button v-if="wallet" :disabled="mintDisabled" @click="onMint" :class="['w-full px-10 py-5 text-xl font-semibold text-center text-white rounded-lg bg-gradient-to-br from-green-600 to-green-400 shadow md:w-auto', mintDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:from-green-700 hover:to-green-500']">
          <svg v-if="isMinting" class="animate-spin h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <template v-else-if="isSoldOut">
            Sold Out
          </template>
          <template v-else-if="isActive">
            Mint
          </template>
          <template v-else>
            Not Started
          </template>
        </button>
        <WalletMultiButton v-else>
          <button class="w-full px-10 py-5 text-xl font-semibold text-center text-white rounded-lg bg-gradient-to-br from-green-600 to-green-400 shadow md:w-auto hover:from-green-700 hover:to-green-500">
            Connect wallet
          </button>
        </WalletMultiButton>
      </div>
    </div>
    <div class="px-10 pb-8">
      <RadioGroup v-model="selected">
        <RadioGroupLabel class="sr-only">
          Choose how many to mint
        </RadioGroupLabel>
        <div class="grid grid-cols-2 sm:grid-cols-4 gap-6">
          <RadioGroupOption as="template" v-for="option in mintOptions" :key="option.name" :value="option" v-slot="{ checked }">
            <div :class="[
              'cursor-pointer focus:outline-none shadow-sm',
              checked ? 'bg-gradient-to-tr from-green-600 to-green-500 border-transparent text-white hover:bg-gray-400' : 'bg-gray-600 border-gray-400 text-gray-100 hover:bg-gray-700',
              'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1']">
              <RadioGroupLabel as="p">
                {{ option.name }}
              </RadioGroupLabel>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>
    <div class="px-10 py-8 bg-gray-900 border-t border-gray-700 bg-gradient-to-b from-gray-800 to-gray-900">
      <h3 class="text-xl font-medium leading-tight text-gray-200">Simple and Fair Minting</h3>
      <p class="my-1 text-gray-400">
        Same price for whitelist and main sale. Space Sneks will remain on sale until all tokens are sold and no funds will be taken if the supply is exhausted.
      </p>
      <div class="mt-6 overflow-hidden">
        <dl class="-mx-8 -mt-8 flex flex-wrap">
          <div class="flex flex-col px-8 pt-8">
            <dt class="order-2 text-base font-medium text-gray-400">
              Remaining
            </dt>
            <dd class="order-1 text-2xl font-extrabold text-gray-200 sm:text-3xl">
              {{ itemsRemaining }}
            </dd>
          </div>
          <div class="flex flex-col px-8 pt-8">
            <dt class="order-2 text-base font-medium text-gray-400">
              Minted
            </dt>
            <dd class="order-1 text-2xl font-extrabold text-gray-200 sm:text-3xl">
              {{ itemsRedeemed }}
            </dd>
          </div>
          <div class="flex flex-col px-8 pt-8">
            <dt class="order-2 text-base font-medium text-gray-400">
              Wallet Balance
            </dt>
            <dd class="order-1 flex items-start text-2xl font-extrabold text-gray-200 sm:text-3xl">
              <div class="mr-2 mt-1.5">
                <SolanaIcon class="w-4 h-4" />
              </div>
              {{ balance.toFixed(2) }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, watch, inject, nextTick } from 'vue'
  import { notify } from '@kyvg/vue3-notification'
  import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
  import { useAnchorWallet } from '@solana/wallet-adapter-vue'
  import { WalletMultiButton } from '@solana/wallet-adapter-vue-ui'
  import { LAMPORTS_PER_SOL } from '@solana/web3.js'
  import { useMutation } from '@vue/apollo-composable'
  import { markWhitelistUsed as whitelistUsedMutation } from '@/gql/presale'
  import { SolanaIcon } from '@ui'
  import ConfettiExplosion from 'vue-confetti-explosion'
  import * as anchor from '@project-serum/anchor'

  import {
    getCandyMachineState,
    mintTokens,
    shortenAddress,
  } from './candy-machine'

  const props = defineProps({
    candyMachineId: Object,
    config: Object,
    connection: Object,
    startDate: Number,
    treasury: Object,
    txTimeout: Number,
  })

  const MINT_PRICE = 55
  const mintOptions = [
    { name: '1 SNEK', value: 1 },
    { name: '2 SNEK', value: 2 },
    { name: '3 SNEK', value: 3 },
    { name: '4 SNEK', value: 4 },
  ]
  const whitelist = inject('whitelist')
  const selected = ref(mintOptions[0])
  const total = computed(() => (selected.value.value * MINT_PRICE) / 100)
  const confetti = ref(false)

  const { mutate: markWhitelistUsed } = useMutation(whitelistUsedMutation)

  const balance = ref(0)
  const isActive = ref(false)
  const isSoldOut = ref(false)
  const isMinting = ref(false)
  const mintDisabled = computed(() => !isActive.value || isSoldOut.value || isMinting.value)

  const itemsAvailable = ref(0)
  const itemsRedeemed = ref(0)
  const itemsRemaining = ref(0)

  const startDate = ref(new Date(props.startDate))

  const wallet = useAnchorWallet()

  const candyMachine = ref({
    id: null,
    connection: null,
    program: null,
  })

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return

        const state = await getCandyMachineState(wallet.value, props.candyMachineId, props.connection)

      itemsAvailable.value = state.itemsAvailable
      itemsRedeemed.value = state.itemsRedeemed
      itemsRemaining.value = state.itemsRemaining

      isActive.value =  new Date() >= state.goLiveDate
      isSoldOut.value = state.itemsRemaining === 0
      startDate.value = state.goLiveDate
      candyMachine.value = state.candyMachine
    })();
  }

  const onMint = async () => {
    try {
      isMinting.value = true
      if (wallet.value && candyMachine.value.program) {
        const mintTxId = await mintTokens(
                                          candyMachine.value,
                                          props.config,
                                          wallet.value.publicKey,
                                          props.treasury,
                                          selected.value.value,
                                          );

        if (!status.err) {
          if (whitelist && whitelist.value) {
            await markWhitelistUsed({ id: whitelist.value.id })
          } else {
            localStorage.setItem('hasMinted2', true)
          }
          notify({ type: 'success', title: 'Minted successfully', text: 'Welcome to the Snek army!' })
          confetti.value = false
          await nextTick()
          confetti.value = true
        } else {
          notify({ type: 'error', title: 'Mint failed', text: `Tx: ${mintTxId}` })
        }
      }
    } catch (err) {
      let message = err.message || 'Minting failed, please try again'
      if (!err.msg) {
        if (err.message.indexOf('0x138')) {
        // Unknown
      } else if (err.message.indexOf('0x137')) {
        message = 'SOLD OUT'
        isSoldOut.value = true
      } else if (err.message.indexOf('0x135')) {
        message = 'Insufficient funds'
      }
    } else {
      if (err.code === 311) {
        message = 'SOLD OUT'
        isSoldOut.value = true
      } else if (err.code === 312) {
        message = 'Minting period hasnt started yet'
      }
    }

    notify({ type: 'error', title: 'Mint failed', text: message })
  } finally {
    if (wallet) {
      const _balance = await props.connection.getBalance(wallet.value.publicKey)
      balance.value = _balance / LAMPORTS_PER_SOL
    }

    isMinting.value = false
    refreshCandyMachineState()
  }
}

watch(wallet, () => {
  (async () => {
    if (wallet) {
      const _balance = await props.connection.getBalance(wallet.value.publicKey)
      balance.value = _balance / LAMPORTS_PER_SOL
    }

    refreshCandyMachineState()
  })();
})

refreshCandyMachineState()
</script>

